<template>
  <div class="row mx-md-0">
    <div class="col-12 p-md-3 py-3 px-0" :class="{'lightgrey_bg': isOdd}">
      <!--DESKTOP-->
      <div class="row m-0 align-items-center d-none d-md-flex position-relative">
        <input class="form-check-input position-absolute p-0" type="checkbox" v-model="isSelected" v-if="item.transactionStatus.transactionStatusID === 14 || item.transactionStatus.transactionStatusID === 5 || item.transactionStatus.transactionStatusID === 4 || item.transactionStatus.transactionStatusID === 12">
        <div class="col-md-2 pe-0 font15 bold">
          <div class="row m-0 align-items-center py-1" :class="{'round': item.organisationID}">
            <div class="col-auto">
              <IconOrganisations size="size12" v-if="item.organisationID" />
              <IconGroups size="size12" v-else-if="item.donorGroupID"/>
              <IconSingleDonor size="size12" v-else />
            </div>
            <div class="col text-truncate">
              <span v-if="item.organisationID">Org: "{{theOrganisation}}"</span>
              <span v-else-if="item.donorGroupID"> Group: "{{theGroup}}"</span>
              <span v-else>
                <span v-if="item.donationMadeBy">{{item.donationMadeBy.firstName}} {{item.donationMadeBy.lastName}}</span>
                <span v-else>{{item.user.firstName}} {{item.user.lastName}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-2 p-0 text-center font15">
          {{item.transactionReference}}
        </div>
        <div class="col p-0 text-center font15">
          {{date}}
        </div>
        <div class="col-md-1 p-0 text-center font15 bold">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col p-0 text-center bold font15">
          {{item.paymentOption.method}}
        </div>
        <div class="col-md-2 p-0 text-center font15">
          {{item.transactionStatus.description}}
        </div>
        <div class="col-md-2 p-0 text-end">
          <div class="row align-items-center justify-content-end">
            <div class="col-auto px-1">
              <Button color="green" btnText="VIEW" size="small" @click="view"/>
            </div>
            <div class="col-auto px-1" v-if="isUK">
              <Button color="gold" icon="arrow" size="xxsmall" v-if="item.giftAid">
                <IconGiftAid size="size16" />
              </Button>
              <Button color="darkgrey" icon="arrow" size="xxsmall" v-else disabled>
                <IconGiftAid size="size16" />
              </Button>
            </div>
            <div class="col-auto px-1" v-else>
              <Button color="gold" icon="arrow" size="xxsmall" @buttonClicked="download18A" v-if="canDownload18" >
                <Icon18a size="size16" />
              </Button>
              <Button color="gold" icon="arrow" size="xxsmall" v-else-if="isSection18" @buttonClicked="popReason = !popReason" >
                <Icon18a size="size16" />
              </Button>
              <Button color="darkgrey" icon="arrow" size="xxsmall" v-else disabled>
                <Icon18a size="size16" />
              </Button>
            </div>
            <div class="abs_popup" v-if="popReason" v-click-outside="closePop">
              <div class="row mx-0">
                <div class="col-12 p-3 text-center small text-white">
                  <div class="bold mb-2">
                    Cannot issue 18A Certificate
                  </div>
                  <div class="mb-2">
                    Please check if the donor has a<br/>valid SA ID Number, Tax Number,<br/>Email Address and Cell Number.
                  </div>
                  <div>
                    <Button color="goldGreen" size="small" btnText="Update my details"  width="100" alignment="justify-content-between" @buttonClicked="updateDetails" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey" icon="arrow" size="xxsmall" @buttonClicked="printReceipt" v-if="item.transactionStatus.transactionStatusID === 14 || item.transactionStatus.transactionStatusID === 5  || item.transactionStatus.transactionStatusID === 4 || item.transactionStatus.transactionStatusID === 12">
                <IconPrint size="size12" />
              </Button>
              <Button color="darkgrey" icon="arrow" size="xxsmall" @buttonClicked="printReceipt" v-else disabled class="opacity">
                <IconPrint size="size12" />
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey" icon="arrow" size="xxsmall" @buttonClicked="downloadReceipt" v-if="item.transactionStatus.transactionStatusID === 14 || item.transactionStatus.transactionStatusID === 5  || item.transactionStatus.transactionStatusID === 4 || item.transactionStatus.transactionStatusID === 12">
                <IconDownload size="size12" />
              </Button>
              <Button color="darkgrey" icon="arrow" size="xxsmall" @buttonClicked="downloadReceipt" v-else disabled class="opacity">
                <IconDownload size="size12" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!--END DESKTOP-->
      <!--MOBILE-->
      <div class="row mx-0 align-items-center d-md-none position-relative font13">
        <div class="col-3 p-0 text-center">
          {{item.transactionReference}}
        </div>
        <div class="col-3 p-0 text-center">
          {{date}}
        </div>
        <div class="col-3 p-0 text-center bold">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-3 p-0 text-center">
          <Button color="green" btnText="VIEW" size="small" @click="view"/>
        </div>
      </div>
      <!--END MOBILE-->
    </div>
    <transition name="fade">
      <Popup v-if="editDetails" @close="(editDetails = false)">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Edit Details
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col-12 mb-3 px-1" v-if="!isUK" ref="theIDNumber">
                <TextField type="text" label="SA ID Number:" placeholder="ID Number" v-model="profile.idNumber" id="idNumber" color="gold" :errors="!idError ? [] : ['Error']" :hasErrors="!idError ? 0 : 1" />
              </div>
              <div class="col-12 mb-3 px-1" v-if="!isUK">
                <TextField type="text" label="Tax Number:" placeholder="Tax Number" v-model="profile.individualTaxNumber" id="individualTaxNumber" color="gold" />
              </div>
              <div class="col-12 mb-3 px-1" v-if="!isUK">
                <TextField type="email" label="Tax Certificate Email Address:" placeholder="Email Address" v-model="profile.taxCertificateEmailAddress" id="taxCertificateEmailAddress" color="gold" />
              </div>
              <div class="col-12 mb-3 px-1">
                <PhoneFieldInt type="tel" :showError="!isValid" label="Mobile No.:" placeholder="Mobile Number" v-model:modelValue="profile.cell" v-model:theError="isValidNumber" id="cell" color="gold" ></PhoneFieldInt>
              </div>
            </div>
          </div>
          <div class="col-11 text-center">
            <Button color="gold" btnText="Save" icon="arrow" @buttonClicked="update18aDetails" :isLoading="isLoading">
              <IconArrowForward />
            </Button>
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'
import vClickOutside from 'click-outside-vue3'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconSingleDonor: defineAsyncComponent(() => import('../components/icons/IconSingleDonor.vue')),
    Icon18a: defineAsyncComponent(() => import('../components/icons/Icon18a.vue')),
    IconDownload: defineAsyncComponent(() => import('../components/icons/IconDownload.vue')),
    IconPrint: defineAsyncComponent(() => import('../components/icons/IconPrint.vue')),
    IconOrganisations: defineAsyncComponent(() => import('../components/icons/IconOrganisations.vue')),
    IconGroups: defineAsyncComponent(() => import('../components/icons/IconGroups.vue')),
    IconGiftAid: defineAsyncComponent(() => import('../components/icons/IconGiftAid.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    PhoneFieldInt: defineAsyncComponent(() => import('../components/PhoneFieldInt.vue')),
    Popup: defineAsyncComponent(() => import('./Popup.vue'))
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  name: 'PastDonationItem',
  props: ['item', 'thekey'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isSelected: false,
      canDownload18: false,
      popReason: false,
      editDetails: false,
      profile: {},
      isLoading: false,
      idError: false
    }
  },
  mounted () {
    this.checkSection18A()
  },
  watch: {
    item: {
      deep: true,
      handler (val) {
        this.checkSection18A()
      }
    },
    isSelected () {
      if (this.isSelected) {
        this.$emit('addSelected')
      } else {
        this.$emit('removeSelected')
      }
    }
  },
  computed: {
    ...mapGetters(['organisations', 'groups', 'section18ATransactions', 'user']),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    isSection18 () {
      if (this.section18ATransactions && this.section18ATransactions.length > 0) {
        const i = this.section18ATransactions.findIndex(e => e.transactionID === this.item.transactionID)
        if (i > -1) {
          return true
          /* vendors contains the element we're looking for, at index "i" */
        } else {
          return false
        }
      } else {
        return null
      }
    },
    theOrganisation () {
      const org = this.organisations.find(organisation => organisation.organisationID === this.item.organisationID)
      return org.registeredName
    },
    theGroup () {
      if (this.groups && this.groups.length > 0) {
        const group = this.groups.find(group => group.donorGroupID === this.item.donorGroupID)
        if (group) {
          return group.donorGroupName
        } else {
          return null
        }
      } else {
        return null
      }
    },
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.transactionDate))
      return date.toLocaleString(DateTime.DATE_SMALL)
    },
    isOdd () {
      return this.thekey % 2
    }
  },
  methods: {
    ...mapActions(['check18A', 'downloadSection18', 'updateUserDetails', 'verifyIDNumber']),
    async checkSection18A () {
      const status = await this.check18A(this.item.transactionID)
      this.canDownload18 = status.canDownloadTaxCertificate
    },
    closePop () {
      this.popReason = false
    },
    async download18A () {
      await this.downloadSection18(this.item.transactionID)
    },
    view () {
      this.$emit('showDetails')
    },
    downloadReceipt () {
      this.$emit('downloadReceipt')
    },
    printReceipt () {
      this.$emit('printReceipt')
    },
    updateDetails () {
      this.profile = { ...this.user }
      this.editDetails = true
      // this.$router.push('/profileedit')
    },
    async update18aDetails () {
      this.isLoading = true
      let idRet = true
      if (!this.isUK && this.profile.idNumber) {
        idRet = await this.verifyID()
      }
      if (idRet) {
        const details = {
          id: this.profile.id,
          email: this.profile.email,
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          title: this.profile.userTitleID,
          zakaatCalculationDate: this.profile.zakaatCalculationDate,
          cellNumber: this.profile.cell,
          giftAid: this.profile.giftAid,
          individualTaxNumber: this.profile.individualTaxNumber,
          taxCertificateEmailAddress: this.profile.taxCertificateEmailAddress,
          idNumber: this.profile.idNumber
        }

        await this.updateUserDetails(details)
        await this.checkSection18A()
        if (this.canDownload18) {
          this.download18A()
        }
        this.isLoading = false
        this.editDetails = false
      }
      // actually submit form
    },
    async verifyID () {
      const ret = await this.verifyIDNumber(this.profile.idNumber)
      if (ret === true) {
        this.idError = false
      } else {
        this.idError = true
        this.isLoading = false
      }
      return ret
    }
  }
}
</script>

<style scoped>
.dark_text {
  color: var(--normal-color-dark);
}
.position_absolute {
  position: absolute;
  left: -2rem;
}
.round {
  background-color: rgb(249, 250, 251);
  border-radius: 2rem;
}
.lightgrey_bg .round {
  background-color: #fff;
}
@media (min-width: 992px) {
  .abs_popup {
    position: absolute;
    border-radius: 15px;
    z-index: 9;
    max-width: 90%;
    box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 50%);
    background-color: rgba(0, 0, 0, 0.8);
    top: 105%;
    width: max-content;
  }
}
</style>
